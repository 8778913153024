
import { HrefOrExplicitRequest } from "../components/core/DataGrid/ReadonlyDataGrid"
import { ApiClient } from "./ApiClient"
import { FilterWithValue, Paginated, SortCriterionWithValue, combineQueryParams, fakePaginated, getApiDescription, getExport, getHref, getPaginated, getResource, paginationAll } from "./Search"
import { DiagnosticLevelId, DiagnosticsOverridingSortingType } from "./WellKnowIds"

export type Diagnostic = {
    id: number
    typeId: number
    customerId?: number
    message: string
    targetUserId: number
    statusId: number
    previousTargetUserId?: number
    activatedTimestamp: Date
    resolvedTimestamp?: Date
    unhandledTimestamp?: Date
}

export type DiagnosticType = {
    id: number
    name: string
    levelId: DiagnosticLevelId
}

export type DiagnosticStatus = {
    id: number
    name: string
}

export type DiagnosticMeasure = {
    activeCount: number
    mostRecentTimestamp?: Date
}

export type DiagnosticsQuery = { targetUserId?: number, customerId?: number, activeOnly?: boolean, overridingSortingType?: DiagnosticsOverridingSortingType }

export class DiagnosticsApi {
    constructor(private readonly api: ApiClient) { }

    public getCountFromQuery = (query: DiagnosticsQuery) =>
        getResource<DiagnosticMeasure>(this.api, `api/tenants/${this.api.tenantId}/diagnostics/measures`, { activeCount: 0 }, { queryParams: query })

    public getAllFromQuery = (query: DiagnosticsQuery) =>
        getResource<Paginated<Diagnostic>>(this.api, `api/tenants/${this.api.tenantId}/diagnostics`, fakePaginated([]), { queryParams: combineQueryParams(query, paginationAll) })
            .then(r => r.items)

    public getDiagnostics = (request: HrefOrExplicitRequest) =>
        getPaginated<Diagnostic>(this.api, getHref(request, `api/tenants/${this.api.tenantId}/diagnostics`))

    public getApi = () =>
        getApiDescription(this.api, `api/tenants/${this.api.tenantId}/diagnostics`)

    public getTypes = () =>
        getResource<DiagnosticType[]>(this.api, `api/tenants/${this.api.tenantId}/diagnostics/types`, [])

    public getStatuses = () =>
        getResource<DiagnosticStatus[]>(this.api, `api/tenants/${this.api.tenantId}/diagnostics/statuses`, [])

    public getDiagnosticsExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) =>
        getExport(this.api, `api/tenants/${this.api.tenantId}/diagnostics/export`, filters, sorting)

}