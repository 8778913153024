import { API_URL } from "../ConfigurationInjection"
import { getToken } from "../auth/Auth"
import { formatEncodedId } from "../utils/customerIdFormatting"
import { toISOStringWIthTimezone } from "../utils/utils"
import { ApiClient, BlobResponse, RequestProgressEvent } from "./ApiClient"
import { ApiDescription, EMPTY_API_DESCRIPTION, FilterWithValue, Paginated, combineQueryParams, fakePaginated, getResource, mapFiltersToQueryParamsObject, postResource } from "./Search"
import { TAB_SESSION_ID, TAB_SESSION_ID_HEADER_NAME } from "./TabSessionId"
import { DocumentAreaId, DocumentDirection } from "./WellKnowIds"

export type Document =
    {
        documentId: number
        documentTypeId: number
        filename?: string
        originalFilename?: string
        documentTypeName: string
        effectiveStartDate?: Date
        effectiveEndDate?: Date
        uploadDate: Date
        mailDate?: Date
        size?: number
        revisionCount: number
        canBeDisplayed: boolean
        canBeConverted: boolean
        canBeDownloaded: boolean
        isPending: boolean
        isArchived: boolean
        supportsArchival: boolean
        applicationId?: number
        isCustomerVisible?: boolean
    }

export type DocumentType =
    {
        documentTypeId: number
        name: string
        validityStartDate?: Date
        validityEndDate?: Date
        requiresStartDate: boolean
        requiresEndDate: boolean
        supportsPendingWithDoc: boolean
    }

export type DocumentsQuery =
    {
        documentAreaId?: DocumentAreaId,
        documentDirection?: DocumentDirection,
        documentTypeId?: number,
        year?: number,
        customerId?: number
        applicationId?: number
        inspectorId?: number
        certificationStandardId?: number
        basicActivityGroupId?: number
        inspectionId?: number
        sampleId?: number
        ticketId?: number
        evaluatorId?: number
        inspectionReimbursementId?: number
        flattenRevisions?: boolean
        applicationOnly?: boolean
        documentIds?: number[]
    }

export type UploadDocumentParams =
    {
        customerId?: number
        applicationId?: number
        inspectorId?: number
        inspectionId?: number
        sampleId?: number
        evaluatorId?: number
        certificationStandardId?: number
        basicActivityGroupId?: number
        ticketId?: number
        inspectionReimbursementId?: number
        markAsPending?: boolean
    }

export type DocumentModel =
    {
        type: number,
        effectiveStartDate?: Date,
        effectiveEndDate?: Date,
        mailDate?: Date,
    }

export type DocumentDetails =
    {
        effectiveStartDate?: Date
        effectiveEndDate?: Date
        mailDate?: Date
        documentDirection?: DocumentDirection
        documentTypeId: number
    }

export type DocumentDetailsResult =
    {
        documentId: number
    }

export type InspectionDocumentType =
    {
        documentTypeId: number
        name: string
        isValidInCustomer: boolean
    }

export type AffirmationUnitError = {
    msg: string
}

export type DocumentIdAndErrors = {
    errors: AffirmationUnitError[]
    documentId: number
    certificationCategoryChanged: boolean
}

export type AffirmationBindingModel = {
    documentDate: Date
    basicActivityGroupId: number
    reason: string
    englishVersion: boolean
    shouldUseGeneralClass: boolean
}

export const EMPTY_AFFIRMATION = {
    documentDate: null as unknown as Date,
    basicActivityGroupId: 0,
    reason: '',
    englishVersion: false,
    shouldUseGeneralClass: false
}

export type AffirmationDocumentType = 'compliance' | 'registration'

export class DocumentsApi {
    static FILE_TIMEOUT_MS = 5 * 60 * 1000

    constructor(private readonly api: ApiClient) { }

    public getDocuments = (query: DocumentsQuery, filters: FilterWithValue[]) =>
        this.api.onUnexpected(
            this.api.execute<Document[]>(200, 'GET', `api/tenants/${this.api.tenantId}/documents`, combineQueryParams(query, mapFiltersToQueryParamsObject(filters))),
            "Δεν είναι δυνατη η εμφάνιση των αρχείων", [])

    public getDocumentsSearchFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<Document>>(200, 'GET', href),
            "Could not load documents (href)", fakePaginated([] as Document[]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/documents`),
            "Could not load api description", EMPTY_API_DESCRIPTION)

    public getDocumentDetails = (documentId: number) =>
        this.api.onUnexpected(
            this.api.execute<DocumentDetails>(200, 'GET', `api/tenants/${this.api.tenantId}/documents/${documentId}/details`),
            "Δεν είναι δυνατη η εμφάνιση του αρχείου", null)

    public getDocument = async (documentId: number) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/documents/${documentId}`, undefined, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public deleteDocument = async (documentId: number) =>
        this.api.onUnexpected(
            this.api.execute<null>(204, 'DELETE', `api/tenants/${this.api.tenantId}/documents/${documentId}`),
            "Could not delete document", undefined)

    public getDocumentAsPdfFullDirectLink = async (documentId: number, displayName?: string) =>
        `${API_URL}/api/tenants/${this.api.tenantId}/documents/${documentId}/direct/${displayName || formatEncodedId(documentId)}?access_token=${await getToken()}&${TAB_SESSION_ID_HEADER_NAME}=${encodeURIComponent(TAB_SESSION_ID)}`

    public getDocumentPDF = async (documentId: number) =>
        this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/documents/${documentId}/pdf`, undefined, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Δεν είναι δυνατή η μετατροπή σε PDF", null)

    public getDocumentTypes = (options: { documentAreaId?: number, certificationStandardId?: number, documentTypeId?: number }) =>
        getResource<DocumentType[]>(this.api, `api/tenants/${this.api.tenantId}/documents/types`, [], { queryParams: options })

    public uploadDocument = (fileInfo: File | undefined, documentId: number | undefined, model: DocumentModel, documentDirection: DocumentDirection, params: UploadDocumentParams, onUploadProgress: RequestProgressEvent) => {
        const data = new FormData() // use FromData to send data while uploading the document

        if (fileInfo) data.append('file', fileInfo)
        if (documentId) data.append('documentId', documentId.toString())
        data.append('documentTypeId', model.type.toString())
        data.append('documentDirection', documentDirection.toString())

        if (model.effectiveStartDate) data.append('effectiveStartDate', toISOStringWIthTimezone(model.effectiveStartDate))
        if (model.effectiveEndDate) data.append('effectiveEndDate', toISOStringWIthTimezone(model.effectiveEndDate))
        if (model.mailDate) data.append('mailDate', toISOStringWIthTimezone(model.mailDate))

        if (params.customerId) data.append('customerId', params.customerId.toString())
        if (params.applicationId) data.append('applicationId', params.applicationId.toString())
        if (params.inspectorId) data.append('inspectorId', params.inspectorId.toString())
        if (params.inspectionId) data.append('inspectionId', params.inspectionId.toString())
        if (params.sampleId) data.append('sampleId', params.sampleId.toString())
        if (params.ticketId) data.append('ticketId', params.ticketId.toString())
        if (params.evaluatorId) data.append('evaluatorId', params.evaluatorId.toString())
        if (params.basicActivityGroupId) { data.append('basicActivityGroupId', params.basicActivityGroupId.toString()) }
        if (params.certificationStandardId) { data.append('certificationStandardId', params.certificationStandardId.toString()) }
        if (params.inspectionReimbursementId) { data.append('inspectionReimbursementId', params.inspectionReimbursementId.toString()) }
        if (params.markAsPending) { data.append('markAsPending', params.markAsPending.toString()) }

        return this.api.onUnexpected(
            this.api.execute<{ documentId: number }>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/upload`, undefined, data, DocumentsApi.FILE_TIMEOUT_MS, onUploadProgress),
            "Δεν είναι δυνατό το ανέβασμα του αρχείου", null)
    }

    public toggleDocumentArchival = (documentId: number) =>
        postResource<null, { isArchived: boolean }>(this.api, `api/tenants/${this.api.tenantId}/documents/${documentId}/archival/toggle`, null)

    public upsertDocumentDetails = (documentId: number, isDateEdit: boolean, isLinkEdit: boolean, shouldMakePendingEdit: boolean, dates?: DocumentDetails, isCustomerVisible?: boolean, ticketId?: number) =>
        this.api.onUnexpected(this.api.execute<DocumentDetailsResult>(200, 'PUT', `api/tenants/${this.api.tenantId}/documents/${documentId}`, undefined, { isCustomerVisible: isCustomerVisible, isDateEdit: isDateEdit, isLinkEdit: isLinkEdit, ticketId: ticketId, shouldMakePendingEdit: shouldMakePendingEdit, ...dates }), "Δεν είναι δυνατή η επεξεργασία των δεδομένων του αρχείου", null)

    public generateCustomerAgreement = async (applicationId: number, type: 'pdf' | 'word') =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/documents/generation/ca/${applicationId}`, { pdf: type === 'pdf' }, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public generateSampleLabDoc = async (sampleId: number, type: 'pdf' | 'word', analysisType: string) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/sl/${sampleId}`, { pdf: type === 'pdf', analysisType: analysisType }, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public generateSampleLabDocAndSend = async (sampleId: number, type: 'pdf' | 'word', analysisType: string, bcc: string) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/email/sl/${sampleId}`, { pdf: type === 'pdf', analysisType: analysisType, bcc: bcc }, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public generateYdhl = async (applicationId: number) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/ydhl/${applicationId}`, undefined, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public generateGdpr = async (applicationId: number) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/gdpr/${applicationId}`, undefined, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public generateApplication = async (applicationId: number) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/application/${applicationId}`, undefined, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public generateCertificate = async (ticketId: number, englishVersion: boolean, effectiveStartDate: Date, effectiveEndDate: Date) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/certificate/${ticketId}`, undefined, { englishVersion: englishVersion, effectiveStartDate: effectiveStartDate, effectiveEndDate: effectiveEndDate }, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Δεν υποστηρίζεται", null)

    public generateComplianceDecision = async (ticketId: number) =>
        postResource<null, BlobResponse>(this.api, `api/tenants/${this.api.tenantId}/documents/generation/complience-decision/${ticketId}`, null, { blob: true, timeout: DocumentsApi.FILE_TIMEOUT_MS })

    public generateProductCertificate = async (ticketId: number, documentDate: Date, assessmentTicketId: number) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/product-certificate/${ticketId}`, undefined, { documentDate: documentDate, assessmentTicketId: assessmentTicketId }, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public generateTransactionCertificate = async (ticketId: number, documentDate: Date, assessmentTicketId: number) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/transaction-certificate/${ticketId}`, undefined, { documentDate: documentDate, assessmentTicketId: assessmentTicketId }, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public generateAppendix = async (query: { inspectionId?: number, applicationId?: number, customerId?: number }, basicActivityGroupId: number) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/appendix`, { ...query, basicActivityGroupId: basicActivityGroupId }, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public generateAffirmation = async (type: AffirmationDocumentType, ticketId: number, model: AffirmationBindingModel) =>
        postResource<AffirmationBindingModel, DocumentIdAndErrors>(this.api, `api/tenants/${this.api.tenantId}/documents/generation/${type}-affirmation/${ticketId}`, model)

    public generatePrefilledInspectionForm = async (inspectionId: number, documentTypeId: number) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/documents/generation/inspection-prefilled-form/${inspectionId}`, { documentTypeId: documentTypeId }, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)


    public getInspectionPrefilledDocumentTypes = (inspectionId: number) =>
        this.api.onUnexpected(
            this.api.execute<InspectionDocumentType[]>(200, 'GET', `api/tenants/${this.api.tenantId}/documents/inspection-prefiled-types`, { inspectionId: inspectionId }), // TODO query or body
            "Could not load document types", [] as InspectionDocumentType[])

    public getCustomerPendingDocsReport = () =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/documents/reports/pending`, null, { blob: true })
}