import { UserId } from './User'

export const UserUnassignedId = -1 as UserId

export const ExportExcelTimeout = 10 * 60000

export enum KnownEntityRelationshipTypeId {
    SharedActivityUnits = 1,
    ΗasSubcontractor = 6,
    CustomerReferedByInspector = 5,
    ΗasExternalSubcontractor = 8,
    CustomerHasBlacklistedInspector = 12,
    TransferredFrom = 14,
    TransferTo = 15,
    TransferredFromExternal = 16,
}

export enum InspectorStatusId {
    Active = 1,
    Inactive = 2
}

export enum InspectorTrainingStatusId {
    Candidate = 1,
    Active = 2,
    Inactive = 3
}

export enum BasicActivityGroupId {
    Crop = 1,
    Livestock = 2,
    Processing = 3,
    Beekeeping = 4,
    Aquaculture = 5,
}

export enum KnownCertificationStandardId {
    EuropeanOrganicStandard = 1,
}

export enum OrganicCertificationCategoryId {
    Organic = 1,
    OrganicUnderConversion = 2,
}

export enum DiffType {
    Modification = 1,
    CollectionAdd = 2,
    CollectionRemove = 3,
    Archive = 4,
}

export enum ApplicationStatusId {
    Active = 1,
    Archived = 2
}

export enum DocumentDirection {
    Inbound = 1,
    Outbound = 2,
}

export enum KnownDocumentTypeId {
    CertificateOfficial = 36,
    ComplianceDecisionCommunication = 125,
    CertificateOfficialEnglish = 173,
}

export enum DocumentAreaId {
    Other = -1,
    Customer = 1,
    Activities = 2,
    Inspection = 3,
    Inspector = 4,
    Subcontractor = 5,
    CustomerAgreement = 6,
    Sampling = 7,
    CustomerOutbound = 8,
    CompanyMiscInbound = 9,
    CompanyMiscOutbound = 10,
    Offers = 11,
    Evaluator = 12,
    InspectorTraining = 13,
    SamplingOutbound = 14,
    Certification = 15,
    TicketDocumentInbound = 16,
    TicketDocumentOutbound = 17,
    CustomerAgreementDeactivation = 18,
    Business2Customer = 19,
    Customer2Business = 20,
    InspectionReimbursement = 21,
    EntersoftIntegration = 22,
    CustomerApplicationAllowedPending = 23,
    InspectionDigitalDocuments = 25
}

export enum KnownTicketTypeDocumentId {
    ComplianceAffirmation = 2,
    ProductCertificate = 3,
    TransactionCertificate = 4,
    AffirmationOfRegistration = 11,
}

export enum TenantId {
    Greece = 1,
    Bulgaria = 2,
}

export enum SupportedLanguage {
    Greek = 1,
    Bulgarian = 2,
    English = 3,
}

export enum LegalTypeId {
    NaturalPerson = 1,
    LegalPerson = 2,
}

export enum InspectionStatusId {
    Draft = 1,
    Assigned = 2,
    Accepted = 3,
    Rejected = 4,
    Active = 5,
    Completed = 6,
    Archived = 7,
    Scheduled = 8,
    Cancelled = 9,
    CustomerAgreementDeactivated = 10,
    DeactivationRequested = 11,
    PlanningChanged = 12
}

export enum InspectionTypeId {
    Regular = 1,
}

export enum TicketPriority {
    P0 = 6,
    P1 = 5,
    P2 = 4,
    P3 = 3,
    P4 = 2,
    P5 = 1,
}

export enum TicketTypeId {
    Assessment = 1,
    IssueDocument = 2,
}

export enum TicketStatusId {
    Backlog = 1,
    Todo = 2,
    Assigned = 3,
    Active = 4,
    Blocked = 5,
    Done = 6,
}

export enum AgreementStatusId {
    Active = 1,
    DeactivationRequested = 2,
    Inactive = 3,
    ArchivedDueToExtension = 4,
}

export enum SampleStatusId {
    Planned = 1,
    SampleTaken = 2,
    LabAssigned = 3,
    SentToLab = 4,
    ResultsReceived = 5,
    SampleNotTaken = 6,
    SampleCancelled = 7,
}

export enum ActivityUnitStatusId {
    Active = 1,
    Snapshot = 2,
    Archived = 3,
}

export enum NotificationTypeId {
    Ping = 1,
    InspectionAssigned = 2,
    InspectionDeclined = 3,
    SampleTaken = 4,
    TicketAssigned = 5,
    AssessmentAssigned = 6,
    LabAssigned = 7,
    InspectionUnassigned = 8,
    InspectionProposedFromTicket = 9,
    InspectionCancelledDueToCaDeactivation = 10,
    EntersoftExporterJobFinished = 29,
    InspectionUpdatedForAssessment = 30,
    InspectionUpdatedForTicket = 31,
}

export const PastureEuCode = "J0000"

export enum TagId {
    BasicInfo = 1,
    Consultant = 2,
    Relationships = 3,
    Subcontractors = 4,
    Attachments = 5,
    ActivitiesCrop = 6,
    ActivitiesLivestock = 7,
    ActivitiesProcessing = 8,
    ActivitiesBeekeeping = 9,
    ActivitiesAquaculture = 10,
    Offers = 11,
    NationalRegulationAgreementAdded = 12,
    NaturlandGermanyAgreementAdded = 13,
    DemeterAgreementAdded = 14,
    NopUsdaAgreementAdded = 15,
    KravSwedenAgreementAdded = 16,
    BioSwisseAgreementAdded = 17,
    Programmes = 18,
    ActivitiesCropArchived = 19,
    ActivitiesLivestockArchived = 20,
    ActivitiesProcessingArchived = 21,
    ActivitiesBeekeepingArchived = 22,
    ActivitiesAquacultureArchived = 23,
    FirstApplication = 24,
    OtherActivities = 25,
}

export enum CheckResultId {
    Ok = 1,
    Warning = 2,
    Block = 3
}

export enum JobTypeId {
    InspectionScheduling = 1,
    EntersoftParser = 2,
    EntersoftExporter = 3,
}

export enum JobStatusId {
    Submitted = 1,
    Running = 2,
    Failed = 3,
    Completed = 4,
    Paused = 5,
}

export enum RegistrationStatusId {
    Completed = 1,
}

export enum KnownByProductId {
    None = 1,
}
export enum ConsultantStatusId {
    Active = 1,
    Inactive = 255,
}

export enum ActivityUnitSpecificKindId {
    LivestockStable = 1,
    LivestockPasture = 2,
    CropParcel = 3,
    VolunteerSpecies = 4,
    ProcessingUnit = 5,
    SelfProducedProcessingUnit = 6,
    UnaffiliatedSubcontractor = 7,
}

export enum SinglePaymentApplicationDocumentKind {
    Year2022 = 1,
    Year2023A = 2,
    Year2023B = 3,
}

export enum RiskAnalysisFactorSeverityId {
    Low = 1,
    Medium = 2,
    High = 3,
}

export enum DiagnosticLevelId {
    Warning = 1,
    Error = 2
}

export enum VirtualAgreementStatusId {
    Active = 1,
    Inactive = 2,
}

export enum SuggestedModelActionId {
    Add = 1,
    Edit = 2
}

export enum DiagnosticsOverridingSortingType {
    Priority = 1,
    ActivationTimeDescending = 2
}

export enum InspectionVersion {
    V1 = 0,
    V1_5 = 1,
    V2 = 2,
}

export enum AnsweredDigitalDocumentRevisionStatusId {
    Initialized = 1,
    Draft = 2,
    Published = 3,
}