import { IconButton, Tooltip, useTheme } from '@mui/material'
import React from 'react'
import { Link } from "react-router-dom"
import { HeartOutline, Stormtrooper } from './components/core/Icons/MdiFacade'
import routes from './routes'

export const Logo = () => {
    return <IconButton sx={{ p: 0, m: 0 }}
        style={{ padding: 0 }}
        color="inherit"
        component={Link}
        to={routes.HOMEPAGE.template}
    >
        {isHeart
            ? <HeartOutline />
            : isMayThe4th ?
                <Tooltip title='May the 4th be with you!'>
                    <span>
                        <Stormtrooper style={{ marginTop: '8px', maxWidth: 23, maxHeight: 23 }} />
                    </span>
                </Tooltip>
                : <LogoSvg width={28} height={28} />
        }
    </IconButton>
}

export const LogoSvg = (props: LogoSvgProps) => {
    const th = useTheme()

    const blinkDelay = props.blinkDelaySeconds ?? Math.floor(Math.random() * (42 - 4 + 1)) + 4

    return <svg width={props.width} height={props.height} viewBox="-100 -100 200 200" xmlns="http://www.w3.org/2000/svg">
        <circle r="100" fill="white" />
        <path
            transform="translate(-30, 0) rotate(-40)"
            d="M 0,-60 Q -60,0 0,60 Q 60,0 0,-60"
            fill={th.palette.primary.main}
        >
            {props.disableSproutAnimation ? null :
                <animateTransform
                    attributeName="transform"
                    type="scale"
                    from="0.1,0.1"
                    to="1,1"
                    begin='0s'
                    dur="0.7s"
                    fill="freeze"
                    additive='sum'
                />
            }
        </path>

        <path
            id="eye"
            transform="translate(40,23) rotate(45)"
            d="M 0,-35 Q -35,0 0,35 Q 35,0 0,-35"
            fill={th.palette.primary.main}
        >
            {props.disableSproutAnimation ? null :
                <animateTransform
                    attributeName="transform"
                    type="scale"
                    from="0.1,0.1"
                    to="1,1"
                    begin='0s'
                    dur="0.7s"
                    fill="freeze"
                    additive='sum'
                />
            }
            <animateTransform
                attributeName="transform"
                type="scale"
                from="1,1"
                to="0,1"
                begin={`${blinkDelay}s`}
                dur="0.25s"
                keySplines="0.42 0 0.58 1"
                keyTimes="0;0.5;1"
                values="1,1;0,1;1,1"
                additive="sum"
            />
        </path>

    </svg>
}

export type LogoSvgProps = {
    disableSproutAnimation?: boolean
    width: number
    height: number
    blinkDelaySeconds?: number
}

const today = new Date()
const isHeart = today.getMonth() === 1 && today.getDate() === 14
const isMayThe4th = today.getMonth() === 4 && today.getDate() === 4