
export default class DateOnly {
    private readonly date: Date

    constructor(date: Date) {
        this.date = new Date(date)
    }

    get day(): number {
        return this.date.getDate()
    }

    get month(): number {
        return this.date.getMonth() + 1
    }

    get year(): number {
        return this.date.getFullYear()
    }

    get rawDate(): Date {
        return this.date
    }

    toString(): string {
        return this.date.toDateString()
    }

    isValid(): boolean {
        return !isNaN(this.date.getTime()) && this.year > 1000
    }

    toLocaleString(locale: string = 'default'): string {
        return this.date.toLocaleDateString(locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        })
    }

    /// Convert to ISO 8601 format
    toJSON(): string {
        const yyyy = this.year
        const mm = this.month.toString().padStart(2, '0')
        const dd = this.day.toString().padStart(2, '0')
        return `${yyyy}-${mm}-${dd}`
    }

    compareTo(other: DateOnly): -1 | 0 | 1 {
        if (this.year < other.year) return -1
        if (this.year > other.year) return 1

        if (this.month < other.month) return -1
        if (this.month > other.month) return 1

        if (this.day < other.day) return -1
        if (this.day > other.day) return 1

        return 0
    }

    diffDays(other: DateOnly): number {
        const diffTime = Math.abs(this.date.getTime() - other.date.getTime())
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    }

    diffDaysInclusiveEnd(other: DateOnly): number {
        return this.diffDays(other) + 1
    }

    static parse(s: string): DateOnly {
        return new DateOnly(new Date(s))
    }

    static isDateOnly(object: unknown): object is DateOnly {
        return object instanceof DateOnly
    }

    static min(date1: DateOnly, date2: DateOnly): DateOnly {
        return date1.compareTo(date2) < 0 ? date1 : date2
    }

    static max(date1: DateOnly, date2: DateOnly): DateOnly {
        return date1.compareTo(date2) > 0 ? date1 : date2
    }

    static today(): DateOnly {
        return new DateOnly(new Date())
    }
}