import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios'
import _ from 'lodash'
import * as uuid from 'uuid'
import { API_URL } from '../ConfigurationInjection'
import { getToken } from '../auth/Auth'
import { toContentLanguage } from '../components/core/Internationalization/InternationalizationContext'
import { ErrorHandler, onUnexpectedIgnore } from '../utils/apiNotificationExtensions'
import DateOnly from '../utils/dateOnly'
import { caseNever } from '../utils/never'
import { TimeOnly } from '../utils/timeOnly'
import { isValidDate, toISOStringWIthTimezone } from '../utils/utils'
import { ActivityCategoriesApi } from './ActivityCategory'
import { ActivityUnitsApi } from './ActivityUnit'
import { ApplicationsApi } from './Application'
import { CertificationApi } from './Certification'
import { CertificationOrganizationsApi } from './CertificationOrganizations'
import { CertificationStandardsApi } from './CertificationStandards'
import { ConsultantsApi } from './Consultant'
import { CustomersApi } from './Customer'
import { CustomerAgreementsApi } from './CustomerAgreements'
import { DiagnosticsApi } from './Diagnostics'
import { DigitalDocumentsApi } from './DigitalDocument'
import { DocumentsApi } from './Document'
import { EmployeeBenefitsApi } from './EmployeeBenefits'
import { EntersoftIntegrationApi } from './EntersoftIntegration'
import { EntityRelationshipsApi } from './EntityRelationships'
import { EvaluatorsApi } from './Evaluator'
import { ExternalUsersApi } from './ExternalUsers'
import { InspectionsApi } from './Inspection'
import { InspectorsApi } from './Inspector'
import { JobsApi } from './Job'
import { JobSpecializationsApi } from './JobSpecialization'
import { LegacyRiskAnalysisApi } from './LegacyRiskAnalysis'
import { LegalTypesApi } from './LegalTypes'
import { OperationsApi } from './Operations'
import { OrganicCertificationCategoriesApi } from './OrganicCertificationCategories'
import { PricingApi } from './Pricing'
import { ProgrammesApi } from './Programme'
import { RegionsApi } from './Region'
import { ReportsApi } from './Reports'
import { SamplesApi } from './Sample'
import { SinglePaymentApplicationsApi } from './SinglePaymentApplication'
import { StatisticsApi } from './Statistics'
import { SubcontractorsApi } from './Subcontractors'
import { TAB_SESSION_ID, TAB_SESSION_ID_HEADER_NAME } from './TabSessionId'
import { TaxOfficesApi } from './TaxOffice'
import { TicketsApi } from './Tickets'
import { UsersApi } from './User'
import { VirtualAgreementsApi } from './VirtualAgreements'
import { SupportedLanguage, TenantId } from './WellKnowIds'

export type BlobResponse =
    {
        blob: Blob
        filename?: string
    }

export type ApiResponse<T, U extends string = "unexpected"> =
    | { kind: "success", correlationId: string, data: T }
    | { kind: U, correlationId: string, message?: string, trace?: any, data?: any, status?: number, failures: undefined }
    | { kind: "unexpected", correlationId: string, message?: string, trace?: any, data?: any, failures?: { [property: string]: string[] }, status?: number }

export type RequestProgressEvent = (loaded: number, total: number) => void
export type SuccessHandler = (method: Method, status: number) => void

export class ApiClient {
    private static axiosClient?: AxiosInstance
    public readonly maxTimeoutMs = 4 * 60 * 1000
    public readonly users = new UsersApi(this)
    public readonly statistics = new StatisticsApi(this)
    public readonly evaluators = new EvaluatorsApi(this)
    public readonly certification = new CertificationApi(this)
    public readonly tickets = new TicketsApi(this)
    public readonly samples = new SamplesApi(this)
    public readonly agreements = new CustomerAgreementsApi(this)
    public readonly applications = new ApplicationsApi(this)
    public readonly customers = new CustomersApi(this)
    public readonly activityUnits = new ActivityUnitsApi(this)
    public readonly pricing = new PricingApi(this)
    public readonly programmes = new ProgrammesApi(this)
    public readonly documents = new DocumentsApi(this)
    public readonly employeeBenefits = new EmployeeBenefitsApi(this)
    public readonly jobSpecializations = new JobSpecializationsApi(this)
    public readonly activityCategories = new ActivityCategoriesApi(this)
    public readonly jobs = new JobsApi(this)
    public readonly taxOffices = new TaxOfficesApi(this)
    public readonly consultants = new ConsultantsApi(this)
    public readonly subcontractors = new SubcontractorsApi(this)
    public readonly inspections = new InspectionsApi(this)
    public readonly inspectors = new InspectorsApi(this)
    public readonly regions = new RegionsApi(this)
    public readonly certificationStandards = new CertificationStandardsApi(this)
    public readonly organicCertificationCategories = new OrganicCertificationCategoriesApi(this)
    public readonly certificationOrganizations = new CertificationOrganizationsApi(this)
    public readonly entityRelationships = new EntityRelationshipsApi(this)
    public readonly legalTypes = new LegalTypesApi(this)
    public readonly externalUsers = new ExternalUsersApi(this)
    public readonly entersoftIntegration = new EntersoftIntegrationApi(this)
    public readonly operations = new OperationsApi(this)
    public readonly singlePaymentApplications = new SinglePaymentApplicationsApi(this)
    public readonly legacyRiskAnalysis = new LegacyRiskAnalysisApi(this)
    public readonly digitalDocuments = new DigitalDocumentsApi(this)
    public readonly reports = new ReportsApi(this)
    public readonly virtualAgreements = new VirtualAgreementsApi(this)
    public readonly diagnostics = new DiagnosticsApi(this)

    private _onUnexpected?: ErrorHandler
    private _onSuccess?: SuccessHandler

    static _Initialize() {
        if (ApiClient.axiosClient) return

        ApiClient.axiosClient = axios.create({
            baseURL: API_URL,
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json",
                [TAB_SESSION_ID_HEADER_NAME]: TAB_SESSION_ID,
            },
            validateStatus: status => status >= 200 && status <= 510
        })

        ApiClient.axiosClient.interceptors.request.use(config => {
            const override = shouldOverride(config)

            if (override) {
                if (!override.paramsAsBody.length) throw new Error('method-override')

                config.transformRequest = (_data: any, _headers?: any) => {
                    config.url = override.url
                    return JSON.stringify({ Query: override.paramsAsBody })
                }
                config.method = 'post'
                config.headers = { ...config.headers, 'X-HTTP-Method-Override': 'GET' }
                config.params = {}
            }
            return config
        })

        ApiClient.axiosClient.interceptors.request.use(config => {
            const transform = (current: any) => {
                const typ = typeof current
                switch (typ) {
                    case 'object':
                        if (Array.isArray(current))
                            for (const it of current)
                                transform(it) // TODO what if it's an array of 'dates'
                        else
                            for (const key in current) {
                                if (!Object.prototype.hasOwnProperty.call(current, key)) continue
                                const element = current[key]
                                if (isValidDate(element))
                                    current[key] = toISOStringWIthTimezone(element)
                                if (element instanceof DateOnly)
                                    current[key] = element.toJSON()
                                else if (element instanceof TimeOnly)
                                    current[key] = element.toJSON()
                                else
                                    transform(element)
                            }
                        break
                    case 'string':
                    case 'number':
                    case 'bigint':
                    case 'boolean':
                    case 'function':
                    case 'undefined':
                    case 'symbol': break
                    default: return caseNever(typ)
                }
            }

            config.transformRequest = (data: any, _headers?: any) => {
                if (data instanceof FormData)
                    return data
                else {
                    const d = _.cloneDeep(data)
                    transform(d)
                    return JSON.stringify(d)
                }
            }

            return config
        })

        ApiClient.axiosClient.interceptors.response.use(x => {
            const transform = (current: any) => {
                const typ = typeof current
                switch (typ) {
                    case 'object':
                        if (Array.isArray(current))
                            for (const it of current)
                                transform(it) // TODO what if it's an array of 'dates'
                        else
                            for (const key in current) {
                                if (!Object.prototype.hasOwnProperty.call(current, key)) continue
                                const element = current[key]
                                if (typeof element === 'string' && looksLikeADateOnly.test(element)) {
                                    current[key] = DateOnly.parse(element)
                                }
                                else if (typeof element === 'string' && looksLikeTimeOnly.test(element)) {
                                    current[key] = TimeOnly.parse(element)
                                }
                                else if (typeof element === 'string' && looksLikeADate.test(element)) {
                                    const d = new Date(element)
                                    if (!Number.isNaN(d.getTime()))
                                        current[key] = d
                                }
                                else
                                    transform(element)
                            }
                        break
                    case 'string':
                    case 'number':
                    case 'bigint':
                    case 'boolean':
                    case 'function':
                    case 'undefined':
                    case 'symbol': break
                    default: return caseNever(typ)
                }
            }

            transform(x.data)

            return x
        })
    }

    constructor(public readonly tenantId: TenantId, public readonly contentLanguage: SupportedLanguage, private readonly reportProgress?: (operation: string, perc: number | undefined) => void) {
    }

    public get onUnexpected(): ErrorHandler {
        return this._onUnexpected ?? onUnexpectedIgnore
    }

    public set onUnexpected(handler: ErrorHandler) {
        if (!this._onUnexpected)
            this._onUnexpected = handler
    }

    public set onSuccess(handler: SuccessHandler) {
        if (!this.onSuccess)
            this._onSuccess = handler
    }

    public async execute<R = object>(expectedStatusCode: number, method: Method, url: string, queryParams?: URLSearchParams | { [key: string]: string | number | undefined | null | boolean | number[] }, body?: any, timeout?: number, onUploadProgress?: RequestProgressEvent, onDownloadProgress?: RequestProgressEvent, blob?: boolean, onSuccessSilent?: boolean, extraHeaders?: Record<string, string>): Promise<ApiResponse<R>> {
        const operation = `${method}${url}${new Date().getTime()}`
        const correlationId = uuid.v4()

        try {
            this.reportProgress?.(operation, undefined)
            let token: string
            try {
                token = await getToken()
            }
            catch (e: any) {
                return {
                    kind: 'unexpected', correlationId: correlationId, message: e.message, data: undefined, trace: e.trace, status: -1
                }
            }

            const headers: any = {
                Authorization: `Bearer ${token}`,
                'Request-Id': correlationId,
                ...extraHeaders,
            }
            const contentLanguage = toContentLanguage(this.contentLanguage)
            if (contentLanguage)
                headers['Content-Language'] = contentLanguage

            const request: AxiosRequestConfig = {
                method: method,
                params: queryParams,
                data: body,
                url: url,
                headers: headers,
                timeout: timeout,
                responseType: blob ? 'blob' : undefined,
                onUploadProgress: !onUploadProgress ? undefined : evt => onUploadProgress(evt.loaded, evt.total),
                onDownloadProgress: !onDownloadProgress ? undefined : evt => onDownloadProgress(evt.loaded, evt.total),
            }

            const response = await ApiClient.axiosClient!.request(request)
            if (response.status === expectedStatusCode) {
                if (!onSuccessSilent)
                    this._onSuccess?.(method, response.status)
                if (blob) {
                    const filenameEncoded = response.headers['x-filename']
                    const blob = { blob: new Blob([response.data], { type: response.headers['content-type'] }), filename: !filenameEncoded ? undefined : decodeURI(filenameEncoded) }
                    return { kind: "success", correlationId: correlationId, data: blob as unknown as R }
                }
                else return { kind: "success", correlationId: correlationId, data: response.data }
            }
            let responseData = response.data
            if (response.data instanceof Blob && response.data?.type === "application/json")
                try {
                    responseData = JSON.parse(await response.data?.text())
                }
                catch { }
            return { kind: "unexpected", correlationId: correlationId, status: response.status, message: responseData?.message, data: responseData, failures: responseData?.failures, trace: responseData?.trace }
        }
        catch (e: any) {
            if (e instanceof Error)
                return { kind: "unexpected", message: `${e.name} : ${e.message}`, data: undefined, correlationId: correlationId, trace: e.stack }
            else
                return { kind: "unexpected", message: e, correlationId: correlationId, data: undefined }
        }
        finally {
            this.reportProgress?.(operation, 100)
        }
    }
}

const QS_LENGTH_LIMIT = 2_000
const QS_PARAMS_LIMIT = 64

// Heuristic of the 'kakias oras'
const shouldOverride = (config: AxiosRequestConfig): null | { url: string | undefined, paramsAsBody: { Key: string, Value: unknown }[] } => {
    if (config.data) return null

    if (config.method !== 'get' && config.method !== 'GET') return null

    // And either URL is long
    const urlSplit = config.url?.split('?')

    if (urlSplit && urlSplit[1]?.length > QS_LENGTH_LIMIT && !config.params && typeof config.params === 'object' && !Object.keys(config.params))
        return {
            url: urlSplit[0],
            paramsAsBody: Array.from(new URLSearchParams(urlSplit[1]).entries()).map(kv => ({ Key: kv[0], Value: kv[1] })),
        }

    if (typeof config.params !== 'object') return null

    // or any one of the string parameters
    // or an excessive number of params
    if (Object.values(config.params).some(v => typeof v === 'string' && v.length > QS_LENGTH_LIMIT) || Object.values(config.params).length > QS_PARAMS_LIMIT)
        return {
            url: config.url,
            paramsAsBody: _.map(config.params, (v, k) => ({ Key: k, Value: v }))
        }

    // URLSearchParams
    if (Symbol.iterator in Object(config.params)) {
        const parameters = Array.from(config.params)

        const hasLongParam = parameters.find((kv: any) => typeof kv[1] === 'string' && kv[1].length > QS_LENGTH_LIMIT)

        if (hasLongParam || parameters.length > QS_PARAMS_LIMIT)
            return {
                url: config.url,
                paramsAsBody: parameters.map((kv: any) => ({ Key: kv[0], Value: kv[1] }))
            }
    }

    return null
}

const looksLikeADateOnly = /^\d{4}-\d{2}-\d{2}$/ // Validate only starting part
const looksLikeTimeOnly = /^\d{2}:\d{2}:\d{2}(?:\.\d+)?$/ // Validate only starting part
const looksLikeADate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/ // Validate only starting part


ApiClient._Initialize()