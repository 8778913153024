import { mdiAccountReactivate, mdiAlphaXBoxOutline, mdiArrowCollapse, mdiArrowExpand, mdiAsterisk, mdiAutoFix, mdiBee, mdiBroom, mdiCalculator, mdiCertificate, mdiCircleOutline, mdiCircleSmall, mdiDotsHorizontal, mdiFactory, mdiFilePdfBox, mdiFileSign, mdiFish, mdiGrass, mdiHeartOutline, mdiHeartPulse, mdiIdCard, mdiIdentifier, mdiKeyChange, mdiListStatus, mdiNumericPositive1, mdiPen, mdiPin, mdiPinOff, mdiPound, mdiRobotConfused, mdiRobotDead, mdiRobotLove, mdiSale, mdiSheep, mdiSprout, mdiSwapVerticalCircle, mdiTableArrowDown, mdiTableArrowUp, mdiTableRowPlusAfter, mdiTableRowPlusBefore, mdiToolbox, mdiToolboxOutline, mdiUnfoldLessHorizontal, mdiUnfoldMoreHorizontal, mdiViewDashboard, mdiViewSplitHorizontal, mdiViewSplitVertical } from "@mdi/js"
import Icon from "@mdi/react"
import { IconProps } from "@mdi/react/dist/IconProps"
import React from "react"

import { SvgIcon, SvgIconProps } from "@mui/material"
import StormtrooperSvg from './Stormtrooper.svg?react'

export const SwapVerticalCircle: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiSwapVerticalCircle} size='21px' {...props} />
export const TableArrowUp: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiTableArrowUp} size='21px' {...props} />
export const TableArrowDown: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiTableArrowDown} size='21px' {...props} />
export const TableRowPlusAfter: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiTableRowPlusAfter} size='21px' {...props} />
export const TableRowPlusBefore: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiTableRowPlusBefore} size='21px' {...props} />
export const ViewDashboard: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiViewDashboard} size='21px' {...props} />
export const HeartPulse: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiHeartPulse} size='21px' {...props} />
export const FileSign: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiFileSign} size='21px' {...props} />
export const Asterisk: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiAsterisk} size='21px' {...props} />
export const Grass: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiGrass} size='21px' {...props} />
export const Sprout: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiSprout} size='21px' {...props} />
export const Sheep: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiSheep} size='21px' {...props} />
export const Bee: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiBee} size='23px' {...props} />
export const Toolbox: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiToolbox} size='23px' {...props} />
export const ToolboxOutline: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiToolboxOutline} size='23px' {...props} />
export const Calculator: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiCalculator} size='23px' {...props} />
export const Factory: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiFactory} size='21px' {...props} />
export const Fish: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiFish} size='21px' {...props} />
export const Sale: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiSale} size='21px' {...props} />
export const AlphaXBoxOutline: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiAlphaXBoxOutline} size='21px' {...props} />
export const ViewSplitHorizontal: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiViewSplitHorizontal} size='21px' {...props} />
export const ViewSplitVertical: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiViewSplitVertical} size='24px' {...props} />
export const Broom: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiBroom} size='21px' {...props} />
export const DotsHorizontal: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiDotsHorizontal} size='21px' {...props} />
export const AutoFix: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiAutoFix} size='21px' {...props} />
export const IdCard: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiIdCard} size='21px' {...props} />
export const Pound: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiPound} size='21px' {...props} />
export const ListStatus: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiListStatus} size='21px' {...props} />
export const KeyChange: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiKeyChange} size='21px' {...props} />
export const AccountReactivate: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiAccountReactivate} size='21px' {...props} />
export const Certificate: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiCertificate} size='21px' {...props} />
export const Pen: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiPen} size='21px' {...props} />
export const ArrowExpand: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiArrowExpand} size='21px' {...props} />
export const ArrowCollapse: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiArrowCollapse} size='21px' {...props} />
export const NumericPositive1: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiNumericPositive1} size='21px' {...props} />
export const HeartOutline: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiHeartOutline} size='21px' {...props} />
export const CircleSmall: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiCircleSmall} size='21px' {...props} />
export const CircleOutline: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiCircleOutline} size='21px' {...props} />
export const Pin: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiPin} size='21px' {...props} />
export const PinOff: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiPinOff} size='21px' {...props} />
export const UnfoldMoreHorizontal: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiUnfoldMoreHorizontal} size='21px' {...props} />
export const UnfoldLessHorizontal: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiUnfoldLessHorizontal} size='21px' {...props} />
export const RobotLove: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiRobotLove} size='25px' {...props} />
export const RobotConfused: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiRobotConfused} size='25px' {...props} />
export const RobotDead: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiRobotDead} size='25px' {...props} />
export const Identifier: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiIdentifier} size='21px' {...props} />
export const Pdf: React.FunctionComponent<Omit<IconProps, 'path'>> = props => <Icon path={mdiFilePdfBox} size='21px' {...props} />

export const Stormtrooper = (props: SvgIconProps) =>
    <SvgIcon {...props} className="stormtrooper-icon-wrapper">
        <StormtrooperSvg />
        <style>{`
            .stormtrooper-icon-wrapper .st0 { fill: white;}
            .stormtrooper-icon-wrapper path { fill: transparent; }
      `}</style>
    </SvgIcon>